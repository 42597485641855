import Vue from 'vue';
import Icon from 'vue-awesome';

import 'vue-awesome/icons/brands/facebook';
import 'vue-awesome/icons/brands/twitter-square';
import 'vue-awesome/icons/envelope-square';
import 'vue-awesome/icons/brands/whatsapp-square';
import 'vue-awesome/icons/brands/telegram-plane';

Vue.component('icon', Icon);
