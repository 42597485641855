export const CATEGORIES = {
  notizie: {
    slug: 'notizie',
    title: 'Notizie',
  },
  cultura: {
    slug: 'cultura',
    title: 'Cultura',
  },
  enogastronomia: {
    slug: 'enogastronomia',
    title: 'Enogastronomia',
  },
  eventi: {
    slug: 'eventi',
    title: 'Eventi',
  },
  arte: {
    slug: 'arte',
    title: 'Arte',
  },
};
