<template>
  <div class="Social">
    <a
      href="https://www.facebook.com/Bookinsicily/"
      target="_blank"
      rel="noopener"
    >
      <img
        class="Facebook"
        :src="buildPath('facebook')"
        alt="Segui Bookinsicily su Facebook"
        draggable="false"
      />
    </a>
    <a
      href="https://www.instagram.com/bookinsicily/"
      target="_blank"
      rel="noopener"
    >
      <img
        class="Instagram"
        :src="buildPath('instagram')"
        alt="Segui Bookinsicily su Instagram"
        draggable="false"
      />
    </a>
    <a href="https://twitter.com/bookinsicily" target="_blank" rel="noopener">
      <img
        class="Twitter"
        :src="buildPath('twitter')"
        alt="Segui Bookinsicily su Twitter"
        draggable="false"
      />
    </a>
  </div>
</template>

<script>
const fragments = {
  facebook: '/assets/icons/social/facebook.svg',
  instagram: '/assets/icons/social/instagram.svg',
  twitter: '/assets/icons/social/twitter.svg',
};

export default {
  props: {
    white: {
      type: Boolean,
      value: false,
    },
  },
  methods: {
    buildPath(type) {
      return this.white
        ? fragments[type].replace(type, `${type}-white`)
        : fragments[type];
    },
  },
};
</script>

<style lang="postcss" scoped>
.Social {
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    height: 32px;
  }

  & a:not(:last-child) {
    margin-right: 15px;
  }

  & .Facebook {
    height: 30px;
  }

  & .Twitter {
    height: 34px;
  }
}
</style>
